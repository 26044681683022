import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ErrorToastService } from 'src/services/errorToast/error-toast.service';
import { HttpdService } from 'src/services/httpd/httpd.service';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';


@Component({
  selector: 'app-add-code-color',
  templateUrl: './add-code-color.component.html',
  styleUrls: ['./add-code-color.component.scss'],
})
export class AddCodeColorComponent implements OnInit {

  @Input() carrinho = [];
  tintas = [];
  listaBrands = []
  listaTypes = [];
  listaMateriais = [];
  
 carrinhosToColor = [];
 jobList = [];
 jobs: any = '';
 rooms: any = '';
 brand = '';
  type = '';
  material = '';
  formCOlor: FormGroup;
  croppedImagePath: string;
  
  isLoading = false;

  imagePickerOptions = {
    maximumImagesCount: 1,
    quality: 50
  };

  constructor(public modalController: ModalController,
    public http: HttpdService,
    private camera: Camera,
    private fb: FormBuilder,
    public actionSheetController: ActionSheetController,
    public error: ErrorToastService) {

    this.formCOlor = this.fb.group({
      brand: new FormControl('', [Validators.required]),
      type: new FormControl('', Validators.required),
      material: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      other: new FormControl(''),
      fotos: new FormArray([]),

    })

  }

  ngOnInit() {
    console.log(this.carrinho)
    this.getTintas();
  }

  dismiss(data) {
    this.modalController.dismiss(data);
  }

  removeItem(rooms){
    const target = this.carrinhosToColor.
    findIndex( item => item.roomUnik === rooms.roomUnik  && item.jobsUnik === rooms.jobsUnik);
   console.log(target)
    this.carrinhosToColor.splice(target, 1);
  }

  addRoom(){
    
    const toadd = {
      roomid: this.rooms?.id_room, 
      roomUnik: this.rooms?.iduniqMobile,
      roomName: this.rooms?.name_room,
      jobsUnik: this.jobs.uniqItemProd,
      jobsRoom: this.jobs.id_sub,
      jobsName: this.jobs.sub_name
    }
    const target = this.carrinhosToColor.find(item => 
      item.roomUnik === this.rooms.iduniqMobile 
      && item.jobsUnik === this.jobs.uniqItemProd);
    if(!target){
      this.carrinhosToColor.push(toadd);
    }
  }

  getJob(event){
    this.jobs='';
   this.jobList = event.target.value.services;
  }

  getTintas() {
    this.http.httpget('User/listaTintas', {})
      .then(res => {
        const tint = [];
        this.tintas = JSON.parse(res.data).data;
        this.tintas.map(item => {
          if (item.id_catProduto == 1 && (tint.filter(i => i.id_brand == item.id_brand).length == 0)) {
            tint.push(item);
          }
        });
        this.listaBrands = tint;
      }).catch(error => this.error.openError(error));
  }


  getTypes(event) {
    this.brand = event.target.value;
    this.listaTypes = [];
    this.formCOlor.controls['type'].setValue(null);
    this.formCOlor.controls['material'].setValue(null);
    const tint = [];
    this.tintas.map(item => {
      if (item.id_catProduto == 1 && item.id_brand == event.target.value && (tint.filter(i => i.id_type == item.id_type).length == 0)) {
        tint.push(item);
      }
    });
    this.listaTypes = tint;
  }




  getMaterial(event) {
    this.type = event.target.value;
    this.listaMateriais = [];
    this.formCOlor.controls['material'].setValue(null);

    const tint = [];
    this.tintas.map(item => {

      if (item.id_catProduto == 1
        && item.id_brand == this.brand
        && item.id_type == event.target.value
        && (tint.filter(i => i.id_material == item.id_material).length == 0)) {
        tint.push(item);
      }
    });
    this.listaMateriais = tint;
  }

  addPhoto(photo){
    const control = <FormArray>this.formCOlor.get('fotos');
    control.push(this.fb.control(photo)) ;
  }



  removePhoto(photo){
    const control = <FormArray>this.formCOlor.get('fotos');
    const target = this.formCOlor.get('fotos').value.findIndex(item => item == photo);
    control.removeAt(target);
  }

 
  fileChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event:any) => {
        //this.img1 =
        this.addPhoto(event.target.result)
      }
      reader.readAsDataURL(event.target.files[0]);  // to trigger onload
    }
  }


  pickImage(sourceType) {
    const options: CameraOptions = {
      quality: 50,
      sourceType: sourceType,
      targetWidth: 600,
      targetHeight: 400,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }
    this.camera.getPicture(options).then((imageData) => {
       this.addPhoto('data:image/jpeg;base64,' + imageData)
    }, (err) => {
      // Handle error
    });
  }

  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [{
        text: 'Load from Library',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Use Camera',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
      ]
    });
    await actionSheet.present();
  }



  Salvar() {
   let cut: any = {}
   
   if(this.formCOlor.controls['material'].value == 0){
    cut = this.tintas.find( item => 
      item.id_brand == this.formCOlor.controls['brand'].value 
      && item.id_catProduto == 1 
     // && item.id_material == this.formCOlor.controls['material'].value 
      && item.id_type == this.formCOlor.controls['type'].value 
      );
      cut.id_material = 0;
      cut.nome_material = this.formCOlor.controls['other'].value
   }else{
    cut = this.tintas.find( item => 
      item.id_brand == this.formCOlor.controls['brand'].value 
      && item.id_catProduto == 1 
      && item.id_material == this.formCOlor.controls['material'].value 
      && item.id_type == this.formCOlor.controls['type'].value 
      );
      
   }
   cut.code = this.formCOlor.controls['code'].value;
      cut.color = this.formCOlor.controls['color'].value;
      cut.fotos = this.formCOlor.controls['fotos'].value; 
      cut.carrinho = this.carrinhosToColor;
       cut.editCode = true;
       this.dismiss(cut);
  }


}
