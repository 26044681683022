import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ErrorToastService } from 'src/services/errorToast/error-toast.service';
import { HttpdService } from 'src/services/httpd/httpd.service';
import { AddTroubleMessageComponent } from '../add-trouble-message/add-trouble-message.component';
import { ShowFotoComponent } from '../show-foto/show-foto.component';

@Component({
  selector: 'app-add-trouble',
  templateUrl: './add-trouble.component.html',
  styleUrls: ['./add-trouble.component.scss'],
})
export class AddTroubleComponent implements OnInit {
  @Input() quoteWk; 
  lista: any = [];
  constructor(public modalController: ModalController,
    public http: HttpdService,
    private fb: FormBuilder,
    public error: ErrorToastService) { }

  ngOnInit() {
    this.getMessages()
  }


  dismiss(data) {
    this.modalController.dismiss(data);
  }

  async addTroble() {
    const modal = await this.modalController.create({
      component: AddTroubleMessageComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        quoteWk: this.quoteWk
      }
    });
    modal.onDidDismiss().then(data=> {
      if(data.data!=''){ this.getMessages();}
    })
    return await modal.present();
  }


  async ShowFoto(foto) {
    const modal = await this.modalController.create({
      component: ShowFotoComponent,
      cssClass: 'fotoModal',
      componentProps:  { foto: foto }
    });
    return await modal.present();
  }





  getMessages(){
    this.http.httpget('User/getMessage', {contractor: this.quoteWk.contractor, id: this.quoteWk.quoteUID})
      .then(res => this.lista = JSON.parse(res.data).data).catch(error => this.error.openError(error));
  }
}
 
