import { Injectable } from '@angular/core';
import { LoadingService } from '../loading/loading.service';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from 'src/environments/environment';
import { DBservice } from '../db/staorage-db.service';
 
@Injectable({
  providedIn: 'root'
})
export class HttpdService {


  constructor(
    private loading: LoadingService,
    private http: HTTP,
    private db: DBservice) { 
      
    }


  server(url) { return `${environment.api}${url}.php`; }

  async getHeader() {
    const headers: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
     // 'token': '30890761-58FD-49B5-9E69-4D187F66AA2D-3p9nI9wDRETJVdeYcAXfFjLJWkLUREde'
    };

    await this.db.getData(environment.userData)
      .then((dados: any) => {
        if (dados) {
          headers.token = `${dados.token}`;
          headers.user = `${dados.idUser}`;
          
        }
      }).catch(error => { });
    return await headers;
  }

  async httpget(url, data) {
    this.http.setDataSerializer('json');
    this.loading.presentLoading('Carregando');
    const header = await this.getHeader();
    const t =  await this.http.get(this.server(url), data, header)  
    this.loading.hideLoader();
    return await t;
  }


  async httppost(url, data) {
    this.http.setDataSerializer('json');
    this.loading.presentLoading('Carregando');
    const header = await this.getHeader();
    const t = await this.http.post(this.server(url), data, header) 
    this.loading.hideLoader();
    return await t;
  }



}
