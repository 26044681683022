import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], filtro: any): any[] {
    if (!items) { return []; }
    if (!filtro.search) { return items; }
    if (filtro.prop) {
      const c = [];
      items.map(cat => {
        cat[filtro.obj].filter(categorias => {
          if (categorias[filtro.prop] === filtro.search) {
            c.push(cat);
          }
        })
      })
      return c;
    }
    return items.filter(it => {
      return it[filtro.obj] === filtro.search;
    });

  }

}
