import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorToastService } from 'src/services/errorToast/error-toast.service';
import { ToastService } from 'src/services/toast/toast.service';
import { ShowFotoComponent } from '../show-foto/show-foto.component';

@Component({
  selector: 'app-contract-show',
  templateUrl: './contract-show.page.html',
  styleUrls: ['./contract-show.page.scss'],
})
export class ContractShowPage implements OnInit {

  @Input() quote;

  constructor(public modalController: ModalController,
    public error: ErrorToastService,
    private toast: ToastService) { }


  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }
 
  async showlabel(foto) {
    const modal = await this.modalController.create({
      component: ShowFotoComponent,
      cssClass: 'my-custom-class',
      componentProps: { foto }
    });
    

    modal.onDidDismiss().then(data=>{})
    return await modal.present();
  } 

  LparseFloat(valor){
    return parseFloat(valor)
  }
}
