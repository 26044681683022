import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {



  isLoading = false;

  constructor(public loadingController: LoadingController) { }

  async presentLoading(messages) {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 6000,
      message: messages
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => {});
        }
      });
    });
  }

  async hideLoader() {

    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingController.dismiss().then(() => {});
      }
    return null;
  }
 
}
