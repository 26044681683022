import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {  HashLocationStrategy, LocationStrategy } from '@angular/common';
import {NgxMaskIonicModule} from 'ngx-mask-ionic'

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallmapService } from 'src/services/callmap/callmap.service';
import { DBservice } from 'src/services/db/staorage-db.service';
import { ErrorToastService } from 'src/services/errorToast/error-toast.service';
import { HttpdService } from 'src/services/httpd/httpd.service';
import { LoadingService } from 'src/services/loading/loading.service';
import { ToastService } from 'src/services/toast/toast.service';
import { SearchPipe } from 'src/pipes/search/search.pipe';
import { HTTP } from '@ionic-native/http/ngx';
import { Camera  } from '@awesome-cordova-plugins/camera/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { AddCodeColorComponent } from './modals/add-code-color/add-code-color.component';
import { AddTroubleComponent } from './modals/add-trouble/add-trouble.component';
import { AddTroubleMessageComponent } from './modals/add-trouble-message/add-trouble-message.component';
import { ShowFotoComponent } from './modals/show-foto/show-foto.component';
import { ContractShowPage } from './modals/contract-show/contract-show.page';
import { HeaderComponent } from './componentes/header/header.component';
 
@NgModule({
  declarations: [AppComponent,  AddCodeColorComponent, ShowFotoComponent, AddTroubleComponent, AddTroubleMessageComponent, ContractShowPage],
  entryComponents: [],
  exports: [AddCodeColorComponent, ShowFotoComponent, AddTroubleComponent, AddTroubleMessageComponent, ContractShowPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskIonicModule.forRoot()
  ],
  providers: [
    SearchPipe,
    StatusBar,
    SplashScreen,
    HTTP,
    Camera,
    CameraPreview,
    NativeStorage,
    HttpdService,
    LoadingService,
    ToastService,
    CallmapService,
    Geolocation,
    DBservice,
    ErrorToastService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }],
  bootstrap: [AppComponent],
})
export class AppModule { }
