import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-show-foto',
  templateUrl: './show-foto.component.html',
  styleUrls: ['./show-foto.component.scss'],
})
export class ShowFotoComponent implements OnInit {
  
  @Input() foto;

  constructor(public modalController: ModalController) { }

  ngOnInit() {  }

  dismiss(data) {
    this.modalController.dismiss(data);
  }
}
