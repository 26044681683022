import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController) { }

  async alerta(messagelista) {
    const toast = await this.toastController.create({
      message: messagelista,
      duration: 3000,
      position: 'middle',
      cssClass: 'toastdiv',
      buttons: [, {
          side: 'end',
          text: '',
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {}
        }
      ]
    });
    toast.present();
   }
 

}
