import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DBservice } from '../db/staorage-db.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorToastService {

  constructor(private router: Router,
              private toast: ToastService,
              private db: DBservice) { }

  openError(item) {
     
    this.toast.alerta(JSON.parse(item.error).msg);
    if (item.status == 401) {
      this.removedata();
    }
  }

  removedata() {
    this.db.removeData(environment.userData)
      .then((item) => {
         this.router.navigate(['login']);
      }).catch(error => { false; });
  }
}
