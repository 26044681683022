import { Injectable } from '@angular/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

@Injectable({
  providedIn: 'root'
})
export class DBservice {

  constructor(private storage: NativeStorage ) { }


  getData(table) {
    return this.storage.getItem(table);
  }

  setData(table, data) {
    return this.storage.setItem(table, data)
  }

  keysData() {
    return this.storage.keys();
  }

  removeData(table) {
    return this.storage.remove(table);
  }

  clear() {
    return this.storage.clear();
  }






}
