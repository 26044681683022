import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ErrorToastService } from 'src/services/errorToast/error-toast.service';
import { HttpdService } from 'src/services/httpd/httpd.service';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

@Component({
  selector: 'app-add-trouble-message',
  templateUrl: './add-trouble-message.component.html',
  styleUrls: ['./add-trouble-message.component.scss'],
})
export class AddTroubleMessageComponent implements OnInit {

  @Input() quoteWk; 
  formCOlor: FormGroup;
  croppedImagePath: string;
  
  isLoading = false;

  imagePickerOptions = {
    maximumImagesCount: 1,
    quality: 50
  };
  constructor(public modalController: ModalController,
    public http: HttpdService,
    private fb: FormBuilder,
    private camera: Camera,
    public actionSheetController: ActionSheetController,
    public error: ErrorToastService) {
      
      this.formCOlor = this.fb.group({
        idmessage: new FormControl(0, [Validators.required]),
        message: new FormControl('', Validators.required),
        fotos: new FormArray([]),
        quoteWk: new FormControl('', Validators.required),
        contract: new FormControl('', Validators.required),
      })
    }

  ngOnInit() {
    this.formCOlor.controls['quoteWk'].setValue(this.quoteWk.quoteUID);
    this.formCOlor.controls['contract'].setValue(this.quoteWk.contractor);
  }

  addPhoto(photo){
    const control = <FormArray>this.formCOlor.get('fotos');
    control.push(this.fb.control(photo)) ;
  }



  removePhoto(photo){
    const control = <FormArray>this.formCOlor.get('fotos');
    const target = this.formCOlor.get('fotos').value.findIndex(item => item == photo);
    control.removeAt(target);
  }


  dismiss(data) {
    this.modalController.dismiss(data);
  }

 
  fileChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event:any) => {
        //this.img1 =
        this.addPhoto(event.target.result)

      }
      reader.readAsDataURL(event.target.files[0]);  // to trigger onload
    }
  }




  

  pickImage(sourceType) {
    const options: CameraOptions = {
      quality: 60,
      sourceType: sourceType,
      //targetWidth: '100%,
      //targetHeight: 600,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }
    this.camera.getPicture(options).then((imageData) => {
       this.addPhoto('data:image/jpeg;base64,' + imageData)
    }, (err) => {
      // Handle error
    });
  }

  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: "Select Image source",
      buttons: [{
        text: 'Load from Library',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: 'Use Camera',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
      ]
    });
    await actionSheet.present();
  }


  Salvar(){
    this.http.httppost('User/saveMessage', this.formCOlor.value)
      .then(res => this.dismiss(res)).catch(error => this.error.openError(error));
  }
}
