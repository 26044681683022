import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CallmapService {

  constructor(private sanitizer: DomSanitizer,
              private platform: Platform) { }

  direcao(lat, long) {

     if (this.platform.is('ios')) {
      return this.sanitizer.bypassSecurityTrustUrl(`maps:?q=${lat}, ${long}`);
    } else if (this.platform.is('android')) {
      return this.sanitizer.bypassSecurityTrustUrl(`geo:?q=${lat}, ${long}`);
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(`geo:?q=${lat}, ${long}`);
    }
  }

}
